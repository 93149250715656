'use client';

//* Components
import IndividualTemplate from '@/components/IndividualPage/template';

//* Style
import ReformsIndividualStyle from './ReformsIndividualStyle';

export default function ReformsIndividualTemplate({ pageData }) {
	return (
		<ReformsIndividualStyle>
			<IndividualTemplate
				pageType={'reforms'}
				pageData={pageData}
			/>
		</ReformsIndividualStyle>
	);
}
